.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #1a472a 0%, #0d2d1a 100%);
    padding: 20px;
    color: #fff;
  }
  
  .playerCard {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 30px;
    width: 100%;
    max-width: 500px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    position: relative;
    text-align: center;
  }
  
  .playerIcon {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.3));
  }
  
  .playerName {
    font-size: 1.8rem;
    margin-bottom: 15px;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    color: var(--color-white);
  }
  
  .playerBet {
    font-size: 1.4rem;
    color: #ffeb3b;
    margin-bottom: 20px;
  }
  
  .betForm {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .betInput {
    padding: 12px 20px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.2);
    color: white;
    font-size: 1.1rem;
    width: 150px;
    transition: all 0.3s ease;
  }
  
  .betInput:focus {
    outline: none;
    border-color: #2c8c4a;
    box-shadow: 0 0 10px rgba(44, 140, 74, 0.3);
  }
  
  .betInput::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  
  .betButton {
    background: linear-gradient(145deg, #2c8c4a 0%, #1f5e37 100%);
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .betButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
  
  .playerCards {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 25px 0;
    flex-wrap: wrap;
  }
  
  .card {
    width: 80px;
    height: 120px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }
  
  .handValue {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 15px 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    color: var(--color-white);
  }
  
  .turnActions {
    margin-top: 25px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
  }
  
  .turnMessage {
    font-size: 1.4rem;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .actionButtons {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .actionButton {
    background: linear-gradient(145deg, #2c8c4a 0%, #1f5e37 100%);
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .actionButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
  
  .doubleConfirm {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  
  .doubleButtons {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .bustMessage {
    font-size: 1.4rem;
    color: #ff4444;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    animation: shake 0.5s ease;
  }
  
  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
  }
  
  .waitingMessage {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 15px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .result {
    padding: 20px;
    border-radius: 15px;
    margin-top: 20px;
    text-align: center;
  }
  
  .resultText {
    font-size: 1.6rem;
    margin: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .win {
    background: linear-gradient(145deg, rgba(46, 204, 113, 0.3) 0%, rgba(39, 174, 96, 0.3) 100%);
    color: #2ecc71;
  }
  
  .lose {
    background: linear-gradient(145deg, rgba(231, 76, 60, 0.3) 0%, rgba(192, 57, 43, 0.3) 100%);
    color: #e74c3c;
  }
  
  .tie {
    background: linear-gradient(145deg, rgba(241, 196, 15, 0.3) 0%, rgba(243, 156, 18, 0.3) 100%);
    color: #f1c40f;
  }
  
  @media (max-width: 768px) {
    .playerCard {
      width: 90%;
      padding: 20px;
    }
  
    .actionButtons {
      flex-direction: column;
    }
  
    .betForm {
      flex-direction: column;
      align-items: center;
    }
  
    .betInput {
      width: 100%;
      max-width: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .playerName {
      font-size: 1.4rem;
    }
  
    .handValue {
      font-size: 1.2rem;
    }
  
    .resultText {
      font-size: 1.2rem;
    }
  
    .card {
      width: 70px;
      height: 105px;
    }
  }

  .leaveButton {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    background: linear-gradient(145deg, #2c8c4a 0%, #1f5e37 100%);
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }