.instructionsOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.instructions {
  position: relative;
  width: 90%;
  max-width: 400px; /* Compact size */
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.header {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.textHeader {
  flex: 1;
}

.title {
  font-size: 1.3rem;
  margin: 0 0 4px 0;
  color: #2c3e50;
}

.subheader {
  font-size: 0.95rem;
  margin: 0;
  color: #7f8c8d;
  font-weight: 500;
}

.instructionsText {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #444;
  margin: 0;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #95a5a6;
  padding: 2px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.closeButton:hover {
  color: #e74c3c;
  background-color: rgba(231, 76, 60, 0.1);
}

@media (max-width: 480px) {
  .instructions {
      width: 95%;
      padding: 15px;
  }
  
  .title {
      font-size: 1.2rem;
  }
  
  .subheader {
      font-size: 0.85rem;
  }
  
  .instructionsText {
      font-size: 0.82rem;
  }
  
  .closeButton {
      top: 5px;
      right: 5px;
      font-size: 1.3rem;
  }
}