.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 999;
  }
  
  .modal-container {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }
  
  .modal-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .modal-body {
    font-size: 16px;
    margin-bottom: 24px;
  }
  
  .modal-button {
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .modal-button:hover {
    background-color: #0069d9;
  }
  