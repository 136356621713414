.proPursuit {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(160deg, #f0f9ff 0%, #e6f4f1 50%, #f5f0ff 100%);
  color: #2d3748;
  padding-bottom: 1rem;
}

.ppContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1600px;
  position: relative;
  padding: 1rem;
  margin-top: 1rem;
}

.ppTitle {
  font-size: 2.5rem;
  color: #2d3748;
  margin: 1rem 0;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.05em;
}

.instructionsBtnPp {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: var(--color-text);
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instructionsBtnPp:hover {
  transform: scale(1.05);
}

.ppGame {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(203, 213, 225, 0.3);
  width: 100%;
  max-width: 1300px;
  margin: 1rem 0;
}

.ppGrid {
  display: grid;
  grid-template-columns: repeat(10, minmax(120px, 1fr));
  gap: 1px;
  background-color: #e2e8f0;
  border-radius: 8px;
  overflow-x: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.gridHeader {
  background-color: #4a5568;
  color: white;
  padding: 15px;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
  white-space: nowrap; /* Prevent header text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
}

.gridCell {
  background-color: #f8fafc;
  padding: 12px;
  color: #2d3748;
  font-weight: 500;
  border-bottom: 1px solid #e2e8f0;
}

.guessWrong {
  background-color: #fee2e2;
}

.guessRight {
  background-color: #dcfce7;
}

.guessClose {
  background-color: #fef9c3;
}

.ppGuessInputTag {
  border: 2px solid #cbd5e1;
  color: #2d3748;
  background: white;
  width: 100%;
  max-width: 100%;
}

.ppGuessInputTag:focus {
  border-color: #7c3aed;
  box-shadow: 0 0 0 2px rgba(124, 58, 237, 0.1);
  outline: none;
}

.customDropdown {
  position: absolute;
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 100;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 4px;
  max-width: 100%;
}

.dropdownItem {
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: var(--secondary-color);
}

.dropdownItem:hover {
  background-color: #f8f9fa;
}

.ppGuessCounter {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}

.guessCounter {
  font-size: 1.5rem;
  color: var(--secondary-color);
  font-weight: 600;
  margin-right: 1rem;
}

.revealBtn {
  background-color: #4a5568;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.revealBtn:hover {
  background-color: #2d3748;
  transform: scale(1.05);
}

.toastBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9998;
}

.toastContainer {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

.toast {
  background-color: #ffffff;
  border: 2px solid #e2e8f0;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  border-radius: 8px;
  min-width: 300px;
  padding: 1rem;
  text-align: center;
}

.toastHeader {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  color: #2d3748;
}

.toastIcon {
  width: 60px;
  height: 60px;
  margin-bottom: 0.75rem;
}

.toastBody {
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 1rem;
}

.toastButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.toastButton {
  background-color: #7c3aed;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.toastButton:hover {
  background-color: #5b21b6;
}

@media (max-width: 768px) {
  .ppTitle {
    font-size: 2rem;
  }

  .gridHeader {
    font-size: 0.8rem;
    padding: 12px;
  }

  .gridCell {
    font-size: 0.85rem;
    padding: 10px;
  }

  .ppGame {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .ppTitle {
    font-size: 1.75rem;
  }

  .gridHeader {
    font-size: 0.75rem;
    padding: 10px;
  }

  .gridCell {
    font-size: 0.8rem;
    padding: 8px;
  }

  .ppGame {
    padding: 1rem;
  }

  .customDropdown {
    max-width: 100%;
  }
}
