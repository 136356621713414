.gridLayout {
    display: grid;
    grid-template-columns: repeat(11, minmax(40px, 65px));
    grid-template-rows: repeat(12, minmax(40px, 65px));
    justify-content: center;
    margin: 2rem auto;
    transform: translateX(-30px);
    max-width: 100vw; 
}

.container {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
}

.wrapperBg {
    background: linear-gradient(90deg, #026670 0%, #033f5a 50%, #4c934c 100%);
    min-height: 100vh;
    overflow: hidden;
}

.titleContainer {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.aroundTheWorldTitle {
    color: white;
    font-size: 1.8rem;
    margin: 0 0.5rem;
    order: 1;
}

.coloredGlobe {
    width: 50px;
    height: 55px;
    flex-shrink: 0;
}

.reverseImg {
    transform: scaleX(-1);
}

.backOfCard {
    width: 100%;
    height: auto;
    aspect-ratio: 2/3;
    margin: 0 0.5rem;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease;
}

/* Group positioning remains unchanged */
.group1 { grid-row-start: 6; grid-row-end: 7; grid-column-start: 1; grid-column-end: 2; transform: rotate(270deg); }
.group2 { grid-row-start: 5; grid-row-end: 6; grid-column-start: 2; grid-column-end: 3; transform: rotate(288deg); }
.group3 { grid-row-start: 4; grid-row-end: 5; grid-column-start: 3; grid-column-end: 4; transform: rotate(306deg); }
.group4 { grid-row-start: 3; grid-row-end: 4; grid-column-start: 4; grid-column-end: 5; transform: rotate(324deg); }
.group5 { grid-row-start: 2; grid-row-end: 3; grid-column-start: 5; grid-column-end: 6; transform: rotate(342deg); }
.group6 { grid-row-start: 1; grid-row-end: 2; grid-column-start: 6; grid-column-end: 7; transform: rotate(0deg); }
.group7 { grid-row-start: 2; grid-row-end: 3; grid-column-start: 7; grid-column-end: 8; transform: rotate(18deg); }
.group8 { grid-row-start: 3; grid-row-end: 4; grid-column-start: 8; grid-column-end: 9; transform: rotate(36deg); }
.group9 { grid-row-start: 4; grid-row-end: 5; grid-column-start: 9; grid-column-end: 10; transform: rotate(54deg); }
.group10 { grid-row-start: 5; grid-row-end: 6; grid-column-start: 10; grid-column-end: 11; transform: rotate(72deg); }
.group11 { grid-row-start: 6; grid-row-end: 7; grid-column-start: 11; grid-column-end: 12; transform: rotate(90deg); }
.group12 { grid-row-start: 7; grid-row-end: 8; grid-column-start: 10; grid-column-end: 11; transform: rotate(108deg); }
.group13 { grid-row-start: 8; grid-row-end: 9; grid-column-start: 9; grid-column-end: 10; transform: rotate(126deg); }
.group14 { grid-row-start: 9; grid-row-end: 10; grid-column-start: 8; grid-column-end: 9; transform: rotate(144deg); }
.group15 { grid-row-start: 10; grid-row-end: 11; grid-column-start: 7; grid-column-end: 8; transform: rotate(162deg); }
.group16 { grid-row-start: 11; grid-row-end: 12; grid-column-start: 6; grid-column-end: 7; transform: rotate(180deg); }
.group17 { grid-row-start: 10; grid-row-end: 11; grid-column-start: 5; grid-column-end: 6; transform: rotate(198deg); }
.group18 { grid-row-start: 9; grid-row-end: 10; grid-column-start: 4; grid-column-end: 5; transform: rotate(216deg); }
.group19 { grid-row-start: 8; grid-row-end: 9; grid-column-start: 3; grid-column-end: 4; transform: rotate(234deg); }
.group20 { grid-row-start: 7; grid-row-end: 8; grid-column-start: 2; grid-column-end: 3; transform: rotate(252deg); }

.guessBtn,
.guessBtnActive {
    padding: 1rem;
    font-size: 1.1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-height: 50px;
}

.guessBtn {
    background: white;
    color: #333;
    border: 2px solid #333;
}

.guessBtnActive {
    background: #4CAF50;
    color: white;
    border-color: #388E3C;
}

.btnContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 1rem;
}

.instructionsIcon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    transition: transform 0.2s ease;
}

.instructionsIcon:hover {
    transform: scale(1.1);
}

/* Mobile adjustments */
@media (max-width: 600px) {
    .gridLayout {
        grid-template-columns: repeat(11, minmax(30px, 45px));
        grid-template-rows: repeat(12, minmax(30px, 45px));
        transform: translateX(-10px);
        margin: 1rem auto;
    }
    .backOfCard {
        margin: 0 0.2rem;
        border-radius: 4px;
    }
    .titleContainer {
        flex-direction: column;
        gap: 0.5rem;
    }
    .aroundTheWorldTitle {
        font-size: 1.5rem;
    }
    .coloredGlobe {
        width: 40px;
        height: 40px;
    }
    .btnContainer {
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        max-width: 100%;
        padding: 0 0.5rem;
    }
    .guessBtn,
    .guessBtnActive {
        padding: 0.75rem;
        font-size: 1rem;
        min-height: 40px;
    }
    .container {
        padding: 0.5rem;
    }
}
