.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a472a 0%, #0d2d1a 100%);
  margin: 0;
  padding: 0;
  color: var(--white-color);
}

.blackjackContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 30px;
  max-width: 600px;
  width: 90%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.gameIcon {
  max-width: 100px;
  margin-bottom: 20px;
  filter: drop-shadow(0 0 10px rgba(0, 255, 0, 0.3));
}

.blackjackContainer h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-family: 'Arial Black', sans-serif;
}

.optionButtons {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
  width: 100%;
  justify-content: center;
}

.button {
  background: linear-gradient(145deg, #2c8c4a 0%, #1f5e37 100%);
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 12px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  background: linear-gradient(145deg, #37a85a 0%, #247340 100%);
}

.buttonIcon {
  font-size: 1.4rem;
}

.joinGameForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.joinGameForm input {
  width: 80%;
  max-width: 350px;
  padding: 12px 20px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  font-size: 1rem;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  transition: all 0.3s ease;
}

.joinGameForm input:focus {
  outline: none;
  border-color: #2c8c4a;
  box-shadow: 0 0 10px rgba(44, 140, 74, 0.3);
}

.joinGameForm input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.joinButton {
  margin-top: 10px;
  padding: 12px 40px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blackjackContainer {
    width: 95%;
    padding: 20px;
  }

  .blackjackContainer h1 {
    font-size: 2rem;
  }

  .optionButtons {
    flex-direction: column;
    gap: 15px;
  }

  .button {
    width: 100%;
    justify-content: center;
    padding: 12px 20px;
  }

  .gameIcon {
    max-width: 80px;
  }
}

@media (max-width: 480px) {
  .joinGameForm input {
    width: 100%;
  }
  
  .blackjackContainer h1 {
    font-size: 1.8rem;
  }
}