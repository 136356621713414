.navbar {
  background-color: #333;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.navList {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navItem {
  margin-right: 10px;
}

.navLink {
  color: var(--white-color);
  text-decoration: none;
  padding: 5px;
  border-radius: 5px;
}

.navLink:hover {
  background-color: var(--accent-color);
  color: var(--black-color);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: var(--black-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px 0px;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdownContentLink {
  display: block;
  text-decoration: none;
  color: var(--white-color);
  margin: 5px 0;
  padding: 5px 10px;
}

.dropdownContentLink:hover {
  background-color: var(--accent-color);
  color: var(--black-color);
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 5px;
}

.hamburgerLine {
  width: 25px;
  height: 3px;
  background-color: var(--white-color);
  margin: 5px 0;
  transition: 0.4s;
}

.hamburgerLineOpen {
  background-color: var(--white-color);
}

.navOpen {
  display: block;
}

.dropdownArrow {
  margin-left: 8px;
  font-size: 0.8em;
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column; 
    align-items: flex-start;
    padding: 10px 15px;
    min-height: 60px;
  }

  .navList {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #333;
    z-index: 1000;
    display: none;
    padding: 10px 0;
    transition: all 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
  }

  .navOpen {
    display: flex !important; 
    flex-direction: column;
    max-height: 1000px;
  }

  .navItem {
    width: 100%;
    padding: 12px 15px;
    margin: 0;
    border-top: 1px solid #444;
  }

  .dropdown {
    flex-direction: column;
  }

  .dropdownContent {
    padding: 0 !important;
    margin-top: 8px;
    background-color: var(--primary-color);

  }

  .dropdownContentLink {
    padding: 10px 35px !important;
    background-color: var(--primary-color);
  }

  .dropdownActive .dropdownContent {
    display: flex !important;
    flex-direction: column;
  }

  .hamburger {
    display: flex !important;
    padding: 10px;
    position: relative;
    z-index: 1001;
  }

  .dropdownHeader {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .dropdownArrow {
    margin-left: 8px;
    font-size: 0.8em;
  }
}

