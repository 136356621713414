.playingGame {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  overflow-x: auto;
  height: 100%;
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
}

.backOfCard {
  width: 60px;
  height: 90px;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.25);
  transform: rotate(-10deg);
}  

.backOfCardDeck {
  width: 70px;
  height: 105px;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.25);
}

.horizontalCard {
  transform: rotate(90deg);
}

.dummyCard {
  width: 100px;
  height: 150px;
  transform: rotate(90deg);
  margin-left: 3rem;
  margin-right: 3rem;
  border-radius: 5px;
}

.finishLine {
  flex: 1;
  text-align: center;
  margin-top: 20%;
  color: #B83227;
  font-size: 1rem;
  font-weight: bold;
}

.contentContainer {
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, #7F7FD5, #91EAE4);
  min-height: 100vh;
}

.deckRow {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.horseRace {
  color: #333;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.horseRaceTitle {
  font-size: 2em;
  margin: 0;
  margin-top: 1rem;
}

.hrIcon {
  width: 120px;
  height: auto;
}

.hrIconRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}

.horseRaceTitleRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subHeader {
  margin: auto;
}

.instructionsIcon {
  position: absolute;
  top: 3rem;
  left: 1rem;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  transition: transform 0.2s ease;
}

.instructionsIcon:hover {
  transform: scale(1.1);
}


@media (max-width: 600px) {
  .flexCol {
    width: 100px;
  }
  .backOfCard {
    width: 40px;
    height: 60px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .backOfCardDeck {
    width: 50px;
    height: 75px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .horizontalCard {
    transform: rotate(90deg) scale(0.8);
  }
  .finishLine {
    font-size: 0.8rem;
    margin-top: 10%;
  }
  .deckRow {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .horseRaceTitle {
    font-size: 1.5em;
    margin-top: 0.5rem;
  }
  .hrIcon {
    width: 80px;
  }
  .instructionsIcon {
    font-size: 1.2rem;
    top: 5rem;
    left: 0.5rem;
  }
}