.HomePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--primary-color);
  height: 100vh;
}

.homeTextContainer {
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
}

.iconsContainer {
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 200px;
}

.content {
  margin-top: 50px;
  text-align: center;
}

.homeTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.beerLogo {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.horse {
  width: 80px;
  height: 80px;
}

.power {
  width: 80px;
  height: 80px;
}

.bus {
  width: 100px;
  height: 80px;
}

.world {
  width: 80px;
  height: 80px;
}

@media (max-width: 768px) {
  .HomePage {
    height: auto;
    min-height: 100vh;
    padding-bottom: 20px;
  }

  .homeTitleContainer {
    flex-direction: column;
    text-align: center;
  }

  .beerLogo {
    margin-right: 0;
    margin-bottom: 20px;
    width: 80px;
    height: 80px;
  }

  .homeTextContainer {
    margin-top: 30px;
    padding: 0 15px;
  }

  .iconsContainer {
    margin-top: 100px;
    width: 100%;
  }

  .icons {
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    margin-top: 30px;
  }

  .icons a {
    flex: 0 1 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }

  .horse,
  .power,
  .bus,
  .world {
    width: 60px;
    height: 60px;
  }

  .bus {
    width: 75px; /* Maintain aspect ratio */
  }

  .content {
    margin-top: 30px;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.4;
    padding: 0 10px;
  }
}