.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #1a472a 0%, #0d2d1a 100%);
    padding: 20px;
    color: #fff;
  }
  
  .dealerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 30px;
    width: 90%;
    max-width: 800px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  }
  
  .dealerIcon {
    width: 120px;
    height: auto;
    margin-bottom: 20px;
    filter: drop-shadow(0 0 10px rgba(0, 255, 0, 0.3));
  }
  
  .deck {
    position: relative;
    margin-bottom: 30px;
  }
  
  .deckCard {
    width: 100px;
    height: 140px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }
  
  .dealerHand {
    display: flex;
    gap: 15px;
    margin: 20px 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .dealerCard {
    width: 80px;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .cardFront {
    width: 100%;
    height: 100%;
  }
  
  .handValue {
    font-size: 1.4rem;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    color: var(--color-white);
  }
  
  .gameControls {
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
  }
  
  .gameCode {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-family: monospace;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  .button {
    background: linear-gradient(145deg, #2c8c4a 0%, #1f5e37 100%);
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
  
  .cancelBtn {
    background: linear-gradient(145deg, #e74c3c 0%, #c0392b 100%);
  }
  
  .startBtn {
    background: linear-gradient(145deg, #3498db 0%, #2980b9 100%);
  }
  
  .turnIndicator {
    background: rgba(255, 255, 255, 0.1);
    padding: 15px 30px;
    border-radius: 10px;
    margin: 20px 0;
    font-size: 1.4rem;
    font-weight: bold;
    backdrop-filter: blur(5px);
  }
  
  .playersSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 25px;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
  }
  
  .playerCard {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 20px;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    position: relative;
    text-align: center;
    }
  
  .active {
    border: 2px solid #ffeb3b;
    box-shadow: 0 0 20px rgba(255, 235, 59, 0.3);
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.02); }
    100% { transform: scale(1); }
  }
  
  .playerIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.3));
  }
  
  .playerName {
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: bold;
    color: #fff;
  }
  
  .playerBet {
    font-size: 1.1rem;
    color: #ffeb3b;
    margin-bottom: 15px;
  }
  
  .playerCards {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 15px 0;
    flex-wrap: wrap;
}
  
  .card {
    width: 60px;
    height: 90px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .win {
    background: linear-gradient(145deg, rgba(46, 204, 113, 0.3) 0%, rgba(39, 174, 96, 0.3) 100%);
  }
  
  .tie {
    background: linear-gradient(145deg, rgba(241, 196, 15, 0.3) 0%, rgba(243, 156, 18, 0.3) 100%);
  }
  
  .lose {
    background: linear-gradient(145deg, rgba(231, 76, 60, 0.3) 0%, rgba(192, 57, 43, 0.3) 100%);
  }
  
  .dealerResult {
    background: rgba(0, 0, 0, 0.3);
    padding: 20px;
    border-radius: 15px;
    margin: 20px 0;
  }
  
  .resultText {
    font-size: 1.8rem;
    margin: 0;
    text-align: center;
    color: #ffeb3b;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .endControls {
    display: flex;
    gap: 20px;
    margin: 20px 0;
  }
  
  .restartBtn {
    background: linear-gradient(145deg, #3498db 0%, #2980b9 100%);
  }
  
  .leaveBtn {
    background: linear-gradient(145deg, #e74c3c 0%, #c0392b 100%);
  }
  
  @media (max-width: 768px) {
    .dealerSection {
      padding: 20px;
      width: 95%;
    }
  
    .buttons {
      flex-direction: column;
    }
  
    .playersSection {
      grid-template-columns: 1fr;
    }
  
    .gameCode {
      font-size: 1.4rem;
    }
  
    .button {
      width: 100%;
      justify-content: center;
      padding: 12px 20px;
    }
  
    .resultText {
      font-size: 1.4rem;
    }
  }
  
  @media (max-width: 480px) {
    .dealerIcon {
      width: 80px;
    }
  
    .dealerCard {
      width: 60px;
      height: 90px;
    }
  
    .playerName {
      font-size: 1.1rem;
    }
  
    .turnIndicator {
      font-size: 1.1rem;
      padding: 10px 20px;
    }
  }