*,
*::before,
*::after {
  box-sizing: border-box;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.formPopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  z-index: 9999;
}

.formContainer {
  background: var(--white-color);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  max-width: 600px;
  width: 100%;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.playerLeagues,
.playerPool,
.playerSelection {
  margin: 1.5rem 0;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.playerLeagues label,
.playerPool label,
.playerSelection label {
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 0.75rem;
  display: block;
  font-size: 1.1rem;
}

.radioButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
}

.radioButton {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background-color: var(--white-color);
  border: 2px solid #dee2e6;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.95rem;
  color: var(--secondary-color);
}

.radioButton:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transform: translateY(-1px);
}

.active {
  background-color: var(--primary-color);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
  font-weight: 600;
}

.ppfTitleRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.ppfTitle {
  font-size: 2rem;
  color: var(--secondary-color);
  margin: 0 0 1.5rem 0;
  text-align: center;
}

.instructionsIcon {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  z-index: 1000;
  transition: all 0.2s ease;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instructionsIcon:hover {
  transform: scale(1.05);
}

.submitButton {
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: var(--success-color);
  color: var(--white-color);
  border: none;
  border-radius: 6px;
  margin-top: 1.5rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.submitButton:hover {
  background-color: var(--white-color);
  color: var(--success-color);
  box-shadow: 0 0 0 2px var(--success-color);
}

.inputText {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #dee2e6;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.inputText:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(173, 216, 230, 0.3);
  outline: none;
}

.customDropdownPp {
  margin-top: 1rem;
}

.playerSelect {
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}

.playerSelect div {
  padding: 0.75rem 1rem;
  transition: background-color 0.2s ease;
}

.formButtonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive design */
@media (max-width: 768px) {
  .formContainer {
    padding: 1.5rem;
    margin: 1rem;
  }

  .ppfTitle {
    font-size: 1.75rem;
  }

  .radioButtons {
    gap: 0.5rem;
  }

  .radioButton {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }

  .playerLeagues,
  .playerPool,
  .playerSelection {
    margin: 1rem 0;
    padding: 0.75rem;
  }
}

@media (max-width: 480px) {
  .formContainer {
    padding: 1.5rem;
    width: 90%;
    margin: 5rem auto; /* adds space from the top */
  }

  .radioButtons {
    flex-direction: column;
    width: 100%;
  }

  .radioButton {
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }
}