.background {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  background: linear-gradient(120deg, #ff9a9e, #a18cd1, #fbc2eb, #fcb69f, #84fab0, #8fd3f4, #ffecd2, #f6d365);
  background-size: 2000% 2000%;
  animation: gradientShift 60s ease-in-out infinite;
}

/* Background gradient shift animation */
@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.stopwatchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  padding: 3rem 2rem;
  background: var(--white-color);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

/* Time Display */
.timeDisplay {
  font-size: 6rem;
  font-weight: bold;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
  letter-spacing: 2px;
}

/* Progress Circle Styling */
.progressCircleContainer {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressCircle {
  transform: rotate(-90deg);
}

.progressCircleBackground {
  fill: none;
  stroke: #e0e0e0;
}

.progressCircleForeground {
  fill: none;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s linear;
}

/* Gradient for progress */
svg defs {
  stop {
    stop-color: var(--accent-color);
  }
  stop:last-child {
    stop-color: var(--success-color);
  }
}

/* Controls Styling */
.controls {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.controlButton {
  padding: 1rem 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  background-color: var(--accent-color);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.controlButton:hover {
  background-color: var(--danger-color);
  transform: translateY(-3px);
}

.controlButton:active {
  transform: scale(0.98);
}

/* Song Information */
.trackDisplay {
  margin-top: 2rem;
  display: none;
}

.header {
  font-size: 1.75rem;
  font-weight: 600;
  color: #555;
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .stopwatchContainer {
    max-width: 90%;
  }

  .timeDisplay {
    font-size: 4rem;
  }

  .controlButton {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }

  .progressCircleContainer {
    margin: 1.5rem 0;
  }
}

.closeButton {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}