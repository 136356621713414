.phScreenBg {
  background-color: var(--primary-color);
  min-height: 100vh;
}

.formContainer {
  max-width: 800px;
  margin: 30px auto;
  padding: 30px;
  background-color: var(--white-color);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.formHr{
  background-color: var(--accent-color);
  height: 1px;
  border: 0;
}

.error {
  background-color: #FFCCCB;
  padding: 15px;
  margin-bottom: 15px;
}

.powerHourTitleRow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.formLabel {
  text-align: left;
}

.formRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}


.inputField {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.nameList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 0;
  margin: 20px 0;
  text-align: left;
}

.nameListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
}

.removeButton {
  padding: 5px 10px;
  margin-left: 15px;
}

.formRow div {
  margin-left: 10px;
}

.formBtn {
  width: 8rem;
}

.formError {
  color: red;
  margin-top: 10px; 
  border: 1px solid red;
  padding: 10px;
  width: 20rem;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
}

.btnInstructions {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .formContainer {
    margin: 15px;
    padding: 20px;
  }

  .formRow {
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0;
  }

  .nameList {
    grid-template-columns: repeat(2, 1fr);
  }

  .inputField {
    width: 100%;
    margin: 10px 0;
  }

  .addButton {
    width: 100%;
    margin: 5px 0;
  }

  .formRow div {
    margin: 8px 0;
    width: 100%;
  }

  .formBtn {
    width: 100%;
    margin: 10px 0;
  }

  .nameListItem {
    font-size: 0.9rem;
  }

  .removeButton {
    padding: 3px 8px;
    margin-left: 8px;
  }
}

@media (max-width: 480px) {
  .nameList {
    grid-template-columns: 1fr;
  }

  .inputField,
  .addButton,
  .formBtn {
    font-size: 16px; /* Larger text for mobile inputs */
  }

  .formRow div label {
    display: flex;
    align-items: center;
  }

  .formRow div input[type="radio"] {
    transform: scale(1.2);
    margin-right: 5px;
    position: relative;
    top: 17px; /* Fine-tune vertical position */
    margin-left: 5rem;
  }
}

/* Add touch-friendly hover states */
@media (hover: none) {
  .addButton:hover,
  .formBtn:hover,
  .removeButton:hover {
    transform: none;
    filter: none;
  }
}