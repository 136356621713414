/* Base Styles */
.tournamentContainer {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.nav {
  display: flex;
  gap: 1rem;
  margin: 20px 0;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.navItem {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  transition: all 0.2s;
}

.navItem:hover {
  background-color: #f0f0f0;
}

.navItem.active {
  background-color: var(--accent-color);
  color: white;
}

.content {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 8px;
}

/* Teams */
.teamsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.addTeam {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.addTeam input,
.addTeam select {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.addPlayerButton {
  padding: 6px 12px;
  background-color: #e9ecef;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
}

.addTeamButton {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.teamList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.teamItem {
  padding: 15px;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 15px;
}

.teamHeader {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.teamInfo {
  display: flex;
  flex-direction: column;
}

.teamName {
  font-weight: bold;
}

.teamCode {
  color: #666;
  font-size: 0.9em;
}

.playerList {
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.playerItem {
  padding: 5px 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

/* Start Button */
.startButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Team Stats */
.teamStats {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.counter {
  font-weight: bold;
  margin-bottom: 10px;
}

.selectedLogos {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.teamLogo {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

/* Schedule */
.scheduleContainer {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.tvColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #eee;
}

.tvColumn h2 {
  background-color: var(--secondary-color);
  color: white;
  padding: 10px;
  margin: 0;
  text-align: center;
}

.scrollColumn {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  max-height: 550px;
}

/* Game Card */
.gameCard {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #fff;
  position: relative;
  transition: transform 0.2s;
}

.gameCard:hover {
  transform: translateY(-2px);
}

.completedGame {
  opacity: 0.7;
}

.gameInfo {
  margin-bottom: 8px;
}

.otRow {
  margin-bottom: 8px;
}

.teamRow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.scoreDisplay {
  font-weight: bold;
  font-size: 1.1rem;
  margin-left: auto;
  margin-right: 20px;
}

.scoreInput {
  width: 50px;
  margin-left: auto;
}

/* Game Actions */
.gameActions {
  display: flex;
  gap: 10px;
}

/* Standings */
.standingsContainer {
  margin-top: 20px;
}

.standingsTable {
  width: 100%;
  border-collapse: collapse;
}

.standingsTable th,
.standingsTable td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: center;
}

.teamCell {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: start;
}

.teamCell img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.standingsTable th {
  cursor: pointer;
  user-select: none;
}

/* Bracket */
.bracketContainer {
  padding: 20px;
}

.bracketGrid {
  display: flex;
  gap: 40px;
  justify-content: start;
}

.bracketColumn {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 220px;
}

.matchContainer {
  background-color: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 10px;
}

.matchContainer h4 {
  margin: 0 0 8px 0;
}

.matchRow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.teamSlot {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  padding: 6px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}

.placeholderSlot {
  flex: 1;
  text-align: center;
  background-color: #eaeaea;
  padding: 6px;
  border-radius: 4px;
}

.selectedTeam {
  border: 2px solid var(--accent-color);
}

.vsText {
  font-weight: bold;
  color: #666;
}

.winnerText {
  margin-top: 6px;
  font-size: 0.9rem;
  color: var(--accent-color);
}

.championBox {
  background-color: #e7ffe7;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 10px;
  border-radius: 6px;
  text-align: center;
}

.teamDisplay {
  display: flex;
  align-items: center;
  gap: 6px;
}

.teamDisplay img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .tournamentContainer {
    padding: 15px;
  }
  .content {
    padding: 15px;
  }
  .navItem {
    min-width: 120px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .tournamentContainer {
    padding: 10px;
  }
  .content {
    padding: 10px;
  }
  .nav {
    justify-content: center;
  }
  .teamsContainer,
  .teamList {
    gap: 0.75rem;
  }
  .scheduleContainer {
    flex-direction: column;
    gap: 10px;
  }
  .tvColumn {
    width: 100%;
  }
  .scrollColumn {
    max-height: 400px;
  }
  .bracketGrid {
    flex-direction: column;
    gap: 20px;
  }
  .bracketColumn {
    min-width: auto;
    width: 100%;
  }
  .standingsTable th,
  .standingsTable td {
    padding: 6px 8px;
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .tournamentContainer {
    padding: 5px;
  }
  .navItem {
    padding: 8px;
    font-size: 0.9rem;
  }
  .content {
    padding: 5px;
  }
  .addTeam input,
  .addTeam select,
  .playerInput {
    font-size: 0.9rem;
    padding: 6px;
  }
  .addTeamButton,
  .addPlayerButton,
  .startButton {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
  .gameCard {
    padding: 8px;
  }
  .scoreInput {
    width: 40px;
  }
  .standingsTable th,
  .standingsTable td {
    padding: 4px 6px;
    font-size: 0.8rem;
  }
  .bracketColumn {
    gap: 15px;
  }
}
