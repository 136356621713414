.cardFlipped {
    transform: translate(-50%, -50%) rotate(-90deg);
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s;
    animation-name: flip-card;
    animation-duration: 1s;
  }
  
  @keyframes flipFard {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(-90deg); }
  }
  
  .blackBtn {
    background-color: black;
    opacity: 50%;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .blackBtnDark {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .blackBtnDark:hover {
    background-color: black;
    color: white;
    cursor: pointer;
  }
  
  .blackBtn:hover {
    background-color: lightgray;
    opacity: 50%;
    color: white;
    cursor: pointer;
  }
  
  .redBtn {
    background-color: #D2042D;
    opacity: 50%;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .redBtn:hover {
    background-color: #F88379;
    opacity: 50%;
    color: black;
    cursor: pointer;
  }
  
  .redBtnDark {
    background-color: #D2042D;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .redBtnDark:hover {
    background-color: #D2042D;
    color: white;
    cursor: pointer;
  }
  
  .greenBtn {
    background-color: green;
    opacity: 50%;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .greenBtnDark {
    background-color: green;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .greenBtn:hover {
    background-color: lightgreen;
    opacity: 50%;
    color: white;
    cursor: pointer;
  }
  
  .greenBtnDark:hover {
    background-color: green;
    color: white;
    cursor: pointer;
  }
  
  .btnRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .btnRow > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  
  .btnRow h2 {
    margin: 0;
  }
  
  .btnRow button {
    padding: 15px 25px;
    font-size: 1.2rem;
    margin: 0 5px;
  }
  
  .card {
    width: 80px;
    height: 120px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
  }
  
  .cardDeck {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  h1.title {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  h2.subHeader {
    text-align: center;
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .shuffleBtn {
    padding: 12px 20px;
    font-size: 1rem;
    margin: 1rem auto;
    display: block;
    border-radius: 5px;
    background-color: var(--accent-color);
    border: 1px solid var(--accent-color);
  }
  
  .shuffleBtn:hover {
    background-color: var(--white-color);
    color: var(--secondary-color);
    border: 1px solid var(--accent-color);
  }
  
  .flippedCards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  
  .rideTheBus {
    background: linear-gradient(to bottom, #00c6ff, #ffffff);
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .card.done {
    border: 2px solid lime;
    box-shadow: 0px 0px 10px 3px lime;
  }
  
  .card.wrong {
    border: 2px solid red;
    box-shadow: 0px 0px 10px 3px red;
  }
  
  .winnerMessage {
    position: fixed;
    top: 0;
    left: -10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  .winnerMessage.show {
    opacity: 1;
    pointer-events: all;
  }
  
  .winnerCard {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .winnerCard h2 {
    margin-top: 0;
  }
  
  .winnerCard p {
    margin-bottom: 0;
  }
  
  .winnerCard button {
    background-color: lightgreen;
    padding: 5px;
    margin-top: 1rem;
  }
  
  .winnerCard button:hover {
    background-color: green;
    padding: 5px;
    margin-top: 1rem;
  }
  
  .rideTheBusTitleRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .instructionsBtnRtb {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    color: var(--text-color);
    font-size: 1.2rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .instructionsBtnRtb:hover {
    transform: scale(1.05);
  }
  
  .rideTheBusTitle {
    font-size: 1.5em;
    margin: 1rem 0;
    text-align: center;
  }
  
  .rtbIcon {
    width: 120px;
    height: auto;
  }
  
  .rtbIconRow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  
  .rtbIconRow img.rtbIcon {
    width: 80px;
    height: auto;
  }
  
  @media (min-width: 600px) {
    .rtbIconRow img.rtbIcon {
      width: 120px;
    }
  }
  
  @media (min-width: 600px) {
    .card {
      width: 100px;
      height: 150px;
    }
    .btnRow button {
      font-size: 1.2rem;
      padding: 15px 25px;
    }
    .rideTheBusTitle {
      font-size: 2em;
    }
    .instructionsBtnRtb {
      top: 1rem;
      left: 1rem;
      width: 36px;
      height: 36px;
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 400px) {
    .btnRow button {
      font-size: 0.9rem;
      padding: 8px 16px;
      margin: 5px;
    }
    .rideTheBusTitle {
      font-size: 1.3em;
    }
  }
  